<template>
  <div>
    <b-card v-if="stampDetail" class="mb-0">
      <template #header>
        <div>
          <font-awesome-icon
            :icon="['fas', 'check-circle']"
            :class="cssChkboxEdit"
          />
          <span class="pl-2">No. {{ stampDetail.number }}</span>
        </div>
      </template>
      <b-row>
        <b-col md="6">
          <InputText
            textFloat="Header Text"
            placeholder="Header Text"
            type="text"
            name="headertext"
            v-model="stampDetail.header_text"
          />
        </b-col>
        <b-col md="6">
          <InputText
            textFloat="Sub Header Text"
            placeholder="Sub Header Text"
            type="text"
            name="subheadertext"
            v-model="stampDetail.sub_header_text"
          />
        </b-col>
        <b-col md="12">
          <InputTextArea
            :rows="4"
            isRequired
            textFloat="Description"
            placeholder="Description"
            id="textarea-rows"
            v-model="stampDetail.description"
            :v="$v.stampDetail.description"
          />
        </b-col>
        <b-col md="6">
          <label class="main-label">
            Minimum Spend Type
            <span class="text-error">*</span>
          </label>

          <b-form-select
            v-model="stampDetail.required_target_type"
            class="mb-3"
          >
            <template #first>
              <b-form-select-option value="" disabled
                >--- Please Select Value ---</b-form-select-option
              >
            </template>

            <template v-if="formMain.required_target_type == 2">
              <b-form-select-option
                v-for="(items, i) in optionsminimumSpendType1"
                :key="i"
                :value="items.value"
                :disabled="isDisable()"
                >{{ items.text }}</b-form-select-option
              >
            </template>
            <template v-if="formMain.required_target_type == 3">
              <b-form-select-option
                v-for="(items, i) in optionsminimumSpendType2"
                :key="i"
                :value="items.value"
                :disabled="isDisable()"
                >{{ items.text }}</b-form-select-option
              >
            </template>
          </b-form-select>

          <div v-if="$v.stampDetail.required_target_type.$error">
            <span class="text-error">Please Select Minimum Spend Type </span>
          </div>
        </b-col>

        <b-col
          v-if="
            stampDetail.required_target_type &&
            (stampDetail.required_target_type === 2 ||
              stampDetail.required_target_type === 3)
          "
        >
          <InputText
            :textFloat="
              formMain.required_target_type === 3
                ? 'Minimum Spend'
                : 'Minumun Quantity'
            "
            placeholder="Minimum Spend"
            type="number"
            oninput="if( this.value.length > 5 )  this.value = this.value.slice(0,5)"
            :disabled="isDisable()"
            @onDataChange="onDataChangeQuantity"
            v-model="stampDetail.quantity"
            inputmode="numeric"
            :v="$v.stampDetail.quantity"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="
          stampDetail.required_target_type &&
          stampDetail.required_target_type === 1
        "
      >
        <b-col cols="12" sm="6">
          <b-row class="flex-column">
            <b-col cols="12">
              <b-form-group label="Reward Type" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="stampDetail.stamp_card_redeem_type_id"
                  :options="rewardTypeOptions"
                  :aria-describedby="ariaDescribedby"
                  :name="'radio-' + stampDetail.number"
                ></b-form-radio-group> </b-form-group
            ></b-col>
            <b-col
              cols="12"
              class="mt-3"
              v-if="stampDetail.stamp_card_redeem_type_id == 2"
            >
              <label class="main-label">
                Privilage Code
                <span class="text-error">*</span>
              </label>

              <b-form-select
                @change="handleChange()"
                v-model="stampDetail.reference_id"
                class="mb-3"
              >
                <template #first>
                  <b-form-select-option value="0" disabled
                    >--- Please Select Value ---</b-form-select-option
                  >
                </template>
                <template
                  v-if="
                    formMain.required_target_type == 3 ||
                    formMain.required_target_type == 2
                  "
                >
                  <b-form-select-option
                    v-for="(items, i) in privilegeList"
                    :key="i"
                    :value="items.id"
                    >{{ items.name }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col>
              <span class="text-error" v-if="errorRefCode"
                >Please Select Value.
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" sm="6">
          <div>
            <UploadFile
              textFloat="Cover Image"
              placeholder="Please Choose File"
              format="all"
              :fileName="stampDetail.image_url"
              v-model="stampDetail.image_url"
              name="thumbnail"
              text="*Please upload only file .png, .jpg with ratio 1:1"
              :accept="acceptType"
              v-on:onFileChange="onImageChange"
              :v="$v.stampDetail.image_url"
            />
            <PreviewBox
              v-on:delete="deleteImage"
              :disabled="isDisable()"
              :showPreviewType="0"
              :showPreview="showPreview"
              :isLoadingImage="isLoadingImage"
              :ratioType="1"
            /></div
        ></b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="6">
          <b-button
            v-if="id === '0'"
            class="border-btn"
            :disabled="isDisable()"
            @click="deleteItem(stampDetail.number, 0)"
            >Delete</b-button
          >
          <b-button
            v-else
            class="border-btn"
            :disabled="isDisable()"
            @click="deleteItem(stampDetail.number, stampDetail.id)"
            >Delete</b-button
          >
        </b-col>
        <b-col cols="6" class="text-right">
          <b-button @click="acceptStampCard" class="submit-btn">
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <ModalConfirm
      ref="confirmModal"
      :text="confirmMsg"
      @handler="deleteStampCard"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import { required, requiredIf } from "vuelidate/lib/validators";
import ModalConfirm from "@/components/modal/ModalConfirm";
import UploadFile from "@/components/inputs/UploadFile";
import PreviewBox from "@/components/PreviewBox.vue";
export default {
  props: {
    stampDetail: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: String,
    },
    isRedeem: {
      required: false,
      type: Number,
    },
    cssChkboxEdit: {
      required: false,
      type: String,
    },
    chkClickFormStamp: {
      required: true,
      type: Boolean,
    },
    chkClickFormStampId: {
      required: true,
      type: Number,
    },
    validateImageList: {
      required: false,
      type: Boolean,
    },
    validateQuantityList: {
      required: false,
      type: Boolean,
    },
    morethan0ErrorList: {
      required: false,
      type: Boolean,
    },

    afterRedeem: {
      required: false,
      type: Boolean,
    },
    formMain: {
      required: true,
      type: Object,
    },
    privilegeList: {
      required: true,
      type: Array,
    },
  },
  components: {
    InputText,
    UploadFile,
    InputTextArea,
    ModalConfirm,
    PreviewBox,
  },
  validations: {
    stampDetail: {
      description: { required },
      required_target_type: {
        required: requiredIf(function (item) {
          if (!item.required_target_type) {
            return true;
          } else {
            return false;
          }
        }),
      },

      quantity: {
        required: requiredIf(function (item) {
          if (item.required_target_type) {
            if (
              parseInt(item.required_target_type) === 2 ||
              parseInt(item.required_target_type) === 3
            ) {
              return true;
            } else if (parseInt(item.required_target_type) === 0) {
              return true;
            } else {
              return false;
            }
          } else {
            if (!item.quantity) {
              if (item.quantity === 0) {
                return true;
              } else {
                return true;
              }
            } else {
              return false;
            }
          }
        }),
      },
      image_url: {
        required: requiredIf(function (item) {
          return item.required_target_type === 1 && !item.image_url;
        }),
      },
    },
  },
  data() {
    return {
      rewardTypeOptions: [
        { value: 2, text: "Privilage" },
        { value: 1, text: "Product" },
      ],
      form: {
        name: "",
        valid_from: "",
        valid_to: "",
        extend_date: 0,
        status: 0,
        product_group_id: 1,
        stampcard_detail: {
          number: 1,
          header_text: "",
          sub_header_text: null,
          description: "",
          required_target_type: "0",
          quantity: 0,
          image_url: null,
        },
      },
      cardselected: "",
      optionsminimumSpendType1: [
        { text: "Quantity", value: 2 },
        { text: "Reward", value: 1 },
      ],
      optionsminimumSpendType2: [
        { text: "Baht", value: 3 },
        { text: "Reward", value: 1 },
      ],
      spendTypeReward: false,
      arryGroupReward: [],
      acceptType: "image/jpeg, image/png",
      showPreview: "",
      isLoadingImage: false,
      image: {
        height: "",
        width: "",
      },
      selected: "",
      isDisabled: false,
      chkClickFormStamp2: this.chkClickFormStamp,
      validateImage: false,
      validateQuantity: false,
      confirmMsg: "",
      numberStamp: 0,
      idStamp: 0,
      morethan0Error: false,
      errorRefCode: false,
    };
  },
  created() {
    if (this.id !== "0" && this.stampDetail.image_url) {
      this.showPreview = this.stampDetail.image_url;
    }
  },
  mounted() {
    if (this.id !== "0" && this.stampDetail.image_url) {
      this.showPreview = this.stampDetail.image_url;
    }
  },
  methods: {
    handleChange() {
      this.errorRefCode = false;
    },
    deleteStampCard(val) {
      if (val) {
        this.$emit("delete", this.numberStamp, this.idStamp);
      }
    },
    onDataChangeQuantity(val) {
      this.stampDetail.quantity = val;
    },
    onChangeSpendType(evt, id) {
      this.stampDetail.required_target_type = parseInt(evt.slice(-1));
    },
    onImageChange(val) {
      this.isLoadingImage = true;
      this.isDisabled = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          if (this.image.width !== this.image.height) {
            this.$swal("Please upload image with ratio 1:1 !", {
              icon: "info",
            });
            this.deleteImage();
            this.isLoadingImage = false;
            this.isDisabled = false;
          } else {
            this.setDataImage(reader.result);
          }
        };
        img.src = await reader.result;
      };
    },
    deleteImage() {
      this.stampDetail.image_url = null;
      this.showPreview = null;
    },
    async setDataImage(base64) {
      let url = await this.saveImagetoDb(base64);
      this.isDisabled = false;
      this.showPreview = url;
      this.stampDetail.image_url = url;
      this.isLoadingImage = false;
      this.$v.stampDetail.$reset();
      this.stampDetail.required_target_type = 1;
    },
    saveImagetoDb: async function (img) {
      let form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      let data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      return data.detail;
    },
    deleteItem(number, id) {
      this.confirmMsg = "Confirm Deletion?";
      this.$refs.confirmModal.show();
      this.numberStamp = number;
      this.idStamp = id;
    },
    isDisable() {
      if (
        this.id !== "0" &&
        this.afterRedeem &&
        this.isRedeem &&
        this.stampDetail.id
      ) {
        return true;
      } else if (this.id !== "0" && this.stampDetail.id && this.isRedeem) {
        return true;
      } else {
        return false;
      }
    },
    acceptStampCard() {
      // this.stampDetail.reference_id = null;
      this.errorRefCode = false;
      this.$v.stampDetail.$touch();
      if (
        this.stampDetail.required_target_type == 1 &&
        this.stampDetail.stamp_card_redeem_type_id == 2 &&
        !this.stampDetail.reference_id &&
        this.stampDetail.reference_id == 0
      ) {
        return (this.errorRefCode = true);
      }

      if (this.$v.stampDetail.$error) {
        return;
      }
      this.$emit("acceptStamp", this.stampDetail);
    },
  },
};
</script>

<style lang="scss" scoped>
.img-box-preview {
  width: 75%;
  padding-bottom: 74.364%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
::v-deep .form-group {
  margin-bottom: 0;
}
.color-chkbox {
  color: #bfbfbf;
  font-size: 17px;
}
.color-chkbox-edit {
  color: var(--primary-color);
  font-size: 17px;
}
::v-deep .detail-format {
  height: auto;
}
</style>
